<template>
    

<div class="valign-wrapper">


    <div class="row valign-wrapper">
      <div class="col s12 card hoverable white " style="min-width: 30vw">


        <form>
          <div class="card-content">


<!--            Badge-->
             <div style="display: flex; align-items: center" class="row">
               <div class="col valign-wrapper">
                 <img src="/icon.png" style="max-width: 2em; margin-right: 10px; " alt="Syrian Arabic Dictionary">
                 Syrian Arabic Dictionary
               </div>
             </div>

            <div class="row">
              <div class="col s12">
                <h4>Sign up</h4>
                to continue to your dashboard
              </div>
            </div>



            <div class="row">
              <div class="col s6">
                <Editbox id="firstname" placeholder="Firstname" v-model="Firstname" />
              </div>
              <div class="col s6">
                <Editbox id="lastname" placeholder="Lastname" v-model="Lastname" />
              </div>
            </div>


            <div class="row">
              <div class="col s12">
                <Editbox id="email" placeholder="Email" v-model="Email" />
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <Editbox id="password" placeholder="Password" v-model="Password" />
              </div>
            </div>


            <div class="row">
              <div class="col s12 left-align">
                Optional:
              </div>
            </div>

            <div class="row">
              <div class="col s6">
                  <Select :items="ages" class="sizeMiddle" placeholder="Age" v-model="ageField" />
              </div>
              <div class="col s6">
                  <Select :items="sexes" class="sizeMiddle"  placeholder="Sex" v-model="sexField" />
              </div>
            </div>

            <div v-if="feedbackMessage !== ''" class="row red lighten-4 red-text text-darken-4" style="border-radius: 0.3em; border-style: solid; border-width: 0.1px">
              {{ feedbackMessage }}
            </div>


          </div>
        </form>

        <div class="card-action right-align">
          <div class="btn-flat waves-effect waves-block" style="position: absolute; left: 0" @click="$router.push('/login')">
            I have an account
          </div>
          <div class="btn z-depth-0 green waves-effect waves-light" @click="signup">Sign up</div>
        </div>
<!--END OF CARD-->
      </div>
    </div>
</div>
    
</template>

<script>
import Editbox from "../components/Controls/Editbox";
import Select from "../components/Controls/Select";

export default {
  name: "Login",
  components: {Editbox, Select},
  data(){
    return {
      Firstname: "",
      Lastname: "",
      Email: "",
      Password: "",
      ageField: "25",
      sexField: "unspecified",
      ages: Array.from({length: 90}, (x, i) => String(i+18)),
      sexes: ['unspecified', 'male', 'female', 'other'],
    }
  },
  computed:{
    feedbackMessage(){
      return this.$store.state.auth.cacheMsg
    }
  },
  created(){
    // clear cache like message
    this.$store.dispatch("authRefresh");
  },
  methods: {
    signup(){
      // make sure not already logged in


        // dispatch login
        this.$store.dispatch('authSignup', {
          email: this.Email,
          password: this.Password,
          firstname: this.Firstname,
          lastname: this.Lastname,
          age: this.ageField,
          sex: this.sexField
        })

    }
  },
  mounted() {

  }
}
</script>
